import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {UserModel} from './_models'
import {WithChildren} from '../../../../_metronic/helpers'
import Keycloak from 'keycloak-js';


type AuthContextProps = {
  keycloak: Keycloak | undefined
  setKeycloak: Dispatch<SetStateAction<Keycloak | undefined>>
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  keycloak: undefined,
  setKeycloak: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const isRun = useRef(false);
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [keycloak, setKeycloak] = useState<Keycloak | undefined>()

  useEffect(() => {
    if (isRun.current) {
      return;
    }
    isRun.current = true;
    //if (keycloak === undefined || keycloak === null) {
      const keycloak_temp = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_REALM ?? '',
        clientId: process.env.REACT_APP_CLIENTID ?? '',
      });
      const ssoURI = window.location.origin + '/silent-check-sso.html';

      keycloak_temp.init({ onLoad: 'check-sso', pkceMethod: 'S256', silentCheckSsoRedirectUri: ssoURI}).then(async (authenticated) => {
        if (authenticated) {
          setKeycloak(keycloak_temp)
          //saveAuth({api_token: keycloak_temp.token ?? ''})
          setCurrentUser({id: 0,
            username: keycloak_temp.tokenParsed?.preferred_username,
            password: '',
            email: keycloak_temp.tokenParsed?.email,
            first_name: keycloak_temp.tokenParsed?.given_name,
            last_name: keycloak_temp.tokenParsed?.family_name,
            emailVerified: keycloak_temp.tokenParsed?.email_verified});
            console.log(keycloak_temp)
        }
        else {
          keycloak_temp.login();
        }
      });
  }, []);

  const logout = () => {
    keycloak?.logout();
    //saveAuth(undefined)
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider value={{keycloak, setKeycloak, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {logout, setCurrentUser, keycloak, setKeycloak} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

/*
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current && keycloak?.authenticated) {
          
          setCurrentUser({id: 0,
            username: keycloak.tokenParsed?.preferred_username,
            password: '',
            email: keycloak.tokenParsed?.email,
            first_name: keycloak.tokenParsed?.given_name,
            last_name: keycloak.tokenParsed?.family_name,
            emailVerified: keycloak.tokenParsed?.email_verified})
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
    };
  
  
    if (keycloak?.authenticated) {
      requestUser();
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, [keycloak]);*/

  useEffect(() => {
    try {
      if (keycloak === undefined || keycloak === null) {
        const keycloak_temp = new Keycloak({
          url: process.env.REACT_APP_KEYCLOAK_URL,
          realm: process.env.REACT_APP_REALM ?? '',
          clientId: process.env.REACT_APP_CLIENTID ?? '',
        });
        const ssoURI = window.location.origin + '/silent-check-sso.html';
        keycloak_temp.init({ onLoad: 'check-sso', pkceMethod: 'S256', silentCheckSsoRedirectUri: ssoURI}).then(async (authenticated) => {
          if (authenticated) {
            setKeycloak(keycloak_temp)
            setCurrentUser({id: 0,
              username: keycloak_temp.tokenParsed?.preferred_username,
              password: '',
              email: keycloak_temp.tokenParsed?.email,
              first_name: keycloak_temp.tokenParsed?.given_name,
              last_name: keycloak_temp.tokenParsed?.family_name,
              emailVerified: keycloak_temp.tokenParsed?.email_verified});
          }
          else {
            keycloak_temp.login();
          }
        });
      }
    }
    catch (error) {
      console.error(error);
      if (!didRequest.current) {
        logout();
      }
    } finally {
      setShowSplashScreen(false);
    }
  }, [keycloak]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
